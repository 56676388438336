@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@800&family=Sedgwick+Ave&display=swap");
.first-screen {
  width: 100%;
  /* height: 444px; */
  /* margin-top:50px; */
  background-color: #efebfb;
}
.container-col-2 {
  display: flex;
  justify-content: center;
  /* padding-bottom:5px; */
}
.container-section-1 {
  margin-left: 50px;
  margin-top: 30px;
  width: 37%;
  /* margin:25px 150px; */
}
.hero-heading {
  height: 137px;
  padding-top: 50px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 46px;
  color: #6412e9;
}
.hero-para {
  height: 15px;
  font-family: "Sedgwick Ave";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  color: #170a2c;
  width: 79%;
}
.sign-button {
  /* width: 180px; */
  height: 40px;
  margin: 50px 0px;
  font-family: "JetBrains Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  align-items: center;
  color: #ffffff;
  background: #6412e9;
  border-radius: 5px;
}

.container-sec-2 {
  display: flex;
  height: 322px;
  margin-left: 90px;
  margin-top: 47px;
}
.container-sec-2 img {
  width: 256px;
}

.banner-divider {
  /* display: block; */
  width: 100%;
  background: linear-gradient(
    90deg,
    #6412e9 0%,
    #fbbb00 34.15%,
    #28e17d 55.87%,
    #6412e9 100%
  );
  height: 75px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  /* display: flex;
  align-items: center; */
  /* padding: 13px 150px; */
  color: #170a2c;
}

@media only screen and (max-width: 600px) {
  .first-screen {
    height: 580px;
  }
  .container-col-2 {
    display: block;
    margin-top: 0px;
  }
  .container-section-1 {
    margin: 0px;
    width: 100%;
    margin-left: 0px;
  }
  h1.hero-heading {
    font-size: 40px;
    padding-top: 20px;
    height: 170px;
    width: 80%;
    margin: auto 5%;
  }
  p.hero-para {
    font-size: 18px;
    line-height: 30px;
    height: 5px;
    margin: auto 5%;
    width: 85%;
  }
  .sign-button {
    margin: 60px 5%;
  }
  .container-sec-2 {
    display: flex;
    height: 278px;
    width: 50%;
    margin-left: 0px;
    margin-top: -25px;
  }
  .container-sec-2 img {
    width: 100%;
    margin-top: 0px;
  }
  .banner-divider {
    margin-top: -5px;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    padding: 10px 10px;
  }
}

@media only screen and (min-width: 670px) and (max-width: 980px) {
  .container-section-1 {
    margin-left: 20px;
    /* width:40%; */
  }

  .hero-heading {
    font-size: 37px;
    height: 160px;
  }
  .hero-para {
    padding-top: 10px;
    font-size: 20px;
    line-height: 30px;
  }
  .container-sec-2 {
    /* height:368px; */
    /* margin-top: 40px; */
    margin-left: 0px;
    /* width:40%; */
    display: flex;
  }
  .container-sec-2 img {
    height: 450px;
    width: 245px;
  }
  .sign-button {
    margin-top: 100px;
  }
  .banner-divider {
    padding: 10px 20px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1650px) {
  .hero-heading {
    width: 77%;
  }
  .hero-para {
    width: 70%;
  }
}
