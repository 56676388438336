@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@800&family=Sedgwick+Ave&display=swap");
.checkout-cart-container {
  padding: 0px 15rem;
  background-color: #efebfb;
  padding-bottom: 4em;
}

.shopping-cart-heading {
  /* padding-top: 25px; */
  font-family: "Barlow";
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 46px;
  color: #6412e9;
}
.items-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 2px 20px;
}
.cart-container {
  width: 68%;
  background: #ffffff;
  box-shadow: 12px 12px 20px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding-bottom: 15px;
}
.order-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 97%; */
  padding: 19px 19px;
}
.order-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  /* width: 39%; */
}
.row-col-con {
  padding-top: 20px;
}
.cart-row {
  /* padding-top: 20px; */
  width: 94%;
  height: 77px;
  margin: auto;
  margin-top: 12px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #b2bcca;
  border-radius: 5px;
}
.cart-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  width: 64%;
}
.price-details {
  width: 29%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-img {
  margin-top: 0px;
}
.img-col img {
  width: 54px;
  height: 54px;
  border: 1px solid #b2bcca;
  border-radius: 5px;
}
.cart-product-title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #646469;
}
.ant-col-5 {
  max-width: 37% !important;
  flex: 1;
}

.product-price {
  left: 69px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #121217;
}
.remove {
  left: 60px;
  color: #646469;
}
.cart-text {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #6412e9;
  padding: 0px 22px;
}
.order-row {
  margin: 40px 10px;
  margin-left: 40px;
}
.order-total {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #121217;
}
.price {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;

  color: #121217;
}
.proceeed-btn {
  /* width: 223px; */
  height: 41px;
  /* left: 20px; */
  background-color: #4afb9c;
  border-radius: 5px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #6412e9;
}
.counter {
  /* width:90px; */
  margin-left: 57px;
  display: flex;
  flex-direction: row;
}

.quantity {
  width: 40px;
  height: 28px;
  border: 1px solid #6412e9;
  border-radius: 5px;
  font-weight: 900;
  font-size: 16px;
  line-height: 14px;
  /* or 88% */
  padding: 7px 7px;
  text-align: center;

  color: #6412e9;
}

@media only screen and (max-width: 600px) {
  .checkout-cart-container {
    width: 100%;
    padding: 0;
    padding-bottom: 70px;
  }
  .cart-container {
    width: 93%;
    margin-left: 15px;
  }
  .shopping-cart-heading {
    text-align: center;
    padding-top: 25px;
  }
  .cart-details {
    padding: 8px 0px;
    width: 100%;
  }
  .price-details {
    /* width: 43%; */
    width: 100%;
  }
  .counter {
    margin-left: 0;
  }
  .img-col img {
    border: 1px solid #b2bcca;
    border-radius: 5px;
    width: 54px;
    height: 54px;
  }
  .cart-row {
    height: auto;
  }
  .product-img {
    padding: 0px 11px;
  }

  .product-quat {
    width: 237px;
  }
  .product-price {
    left: 13px;
    padding: 8px 0;
  }
  .remove {
    left: 3rem;
  }
  .order-details {
    flex-direction: column;
    gap: 1rem;
    padding: 21px 10px;
    padding-top: 51px;
  }
  .order-summary {
    margin-left: -6rem;
    gap: 1px;
  }

  .order-total {
    margin-left: 20px;
  }
  .price {
    margin: auto 50px;
  }
  .proceed-btn {
    width: 100%;
  }
  .proceeed-btn {
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 786px) {
  .checkout-cart-container {
    padding: 0;
    padding-bottom: 8em;
  }
  .cart-container {
    width: 89%;
    margin: 0 34px;
  }
  .shopping-cart-heading {
    text-align: center;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1250px) {
  .checkout-cart-container {
    padding: 0px 2rem;
    padding-bottom: 6rem;
  }
  .cart-container {
    width: 84%;
  }
  .counter {
    margin-left: 23px;
  }
  .product-price {
    left: 31px;
  }
  .remove {
    left: 17px;
  }
}
