.shipping-checkout-container {
  padding: 0px 16rem;
}

.shipping-container {
  background: #ffffff;
  box-shadow: 12px 12px 20px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 80%;

  padding: 19px 24px;
  margin-bottom: 6rem;
}
.shipping-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}
.radio-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.radio-select {
  border-radius: 4px;
  border: 1px solid #b2bcca;
  padding: 9px 8px 0px;
}
.ant-radio-button-wrapper {
  height: 66px;
}

.radio-details {
  display: flex;
  justify-content: space-between;
  gap: 41px;
}

.img-ship {
  margin-left: 6rem;
  margin-top: -18px;
}
.ship-price {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.track-detail {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #4f4f4f;
}

/* media */
@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .shipping-checkout-container {
    padding: 0px 7rem;
  }
  .radio-details {
    gap: 7px;
  }
  .img-ship {
    margin-left: 0;
    margin-left: 42px;
  }
  .shipping-container {
    padding: 19px 19px;
  }
}

@media only screen and (max-width: 700px) {
  .shipping-checkout-container {
    padding: 0;
  }
  .shipping-container {
    margin-left: 1rem;
    width: 92%;
    /* margin-bottom: 57px; */
  }
  .img-ship {
    margin-left: 0;
    margin-top: -2px;
  }
  .radio-details {
    gap: 6px;
  }
}
