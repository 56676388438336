@import "antd/dist/reset.css";
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700;800&family=JetBrains+Mono&family=Open+Sans:wght@400;500;600;700&family=Roboto&family=Sedgwick+Ave&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Barlow",
    sans-serif, "Sedgwick Ave", cursive, "JetBrains Mono", monospace, "Roboto",
    sans-serif, "Open Sans", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a,
Link {
  text-decoration: none;
}
