.main-nav {
  display: flex;
  flex-direction: row;
  background-color: #0f0a2c;
  height: 60px;
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0 24px;
  z-index: 99;
}
.logo h2 {
  margin: 10px 10px 0px 140px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 40px;
  display: flex;
  color: #ffffff;
}
.navbar {
  cursor: pointer;
  justify-content: space-between;
}
.menu-list {
  display: flex;
  list-style: none;
  margin-top: 20px;
  gap: 4rem;
}
.menu-title {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: right;

  color: #ffffff;
}

.navbar-right {
  display: flex;
  cursor: pointer;
  width: 40%;
  justify-content: flex-end;
}
.btntag {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #ffffff;
  margin-top: 20px;
  margin-right: 10px;
}
.navbar-right img {
  background-color: White;
  border-radius: 35px;
  width: 35px;
  height: 35px;
  margin: 15px 0px;
  margin-left: 18px;
}

.hamburger {
  display: none;
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #0f0a2c;
  cursor: pointer;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: translateY(-50%);
  left: 13px;
  top: 30px;
}
.nav-link {
  color: #ffffff;
}
.btntag-mobile {
  display: none;
}
@media screen and (max-width: 700px) {
  .hamburger {
    display: block;
    position: absolute;
  }

  .navbar-expanded {
    display: none;
  }

  .menu-list {
    position: absolute;
    top: 39px;
    left: 0px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 200px;
    background-color: #0f0a2c;
    gap: 0;
  }
  .menu-lists {
    padding: 21px 17px;
    margin: 0 -16px;
  }
  .menu-title {
    width: 100%;
    margin: 0;
    padding: 21px 0px;
    margin: 0px -16px;
  }
  .navbar.expanded ul {
    display: none;
  }
  .btntag-mobile {
    display: block;
    margin-left: inherit;
  }
  .navbar-right {
    width: 95%;
  }
  .navbar-right img {
    margin: 15px -21px;
  }
  .login-nav {
    padding: 0px 9rem;
  }
  .login-nav,
  .btn-login {
    font-family: "JetBrains Mono";
    font-style: normal;
    font-weight: 700;
    /* font-size: 16px; */
    line-height: 21px;
    background: #6412e9;
    border-color: #6412e9;
    color: #ffffff;
  }
  .btntag {
    display: none;
  }
  .logo h2 {
    font-size: 25px;
    position: absolute;
    /* margin-left: 9em; */
    margin: 11px 8px 10px 5rem;
  }
  .ant-badge .ant-badge-count {
    right: -14px;
    top: 2em;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1100px) {
  .btntag {
    font-size: 14px;
  }
  /* .navbar-right {
    width: 41%;
  } */
  .navbar-right img {
    margin: 13px 10px;
  }
  .logo h2 {
    margin: 10px 10px 0px 12px;
    font-size: 24px;
    line-height: 40px;
  }
  .ant-badge .ant-badge-count {
    right: 10px;
    top: 18px !important;
  }
}
